import { useTranslation } from "next-i18next"
import * as yup from "yup"

// Personal Information for Wizard
export interface PersonalInformation {
  name: string
  surname: string
  profession: string
  industry_id?: number | null | string
  industry?: string | null
  gender: string
  birth_date: string
}

export const PeresonalInformationWizardSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    name: yup.string().required(t("NAME_MANDATORY")),
    surname: yup.string().required(t("SURNAME_MANDATORY")),
    profession: yup.string().required(t("PROFESSION_MANDATORY")),
    birth_date: yup.string().required(t("BIRTHDATE_MANDATORY")),
    gender: yup.string().required(t("GENDER_MANDATORY")),
    industry_id: yup.string().required(t("INDUSTRY_MANDATORY")),
    industry: yup.string().when("industry_id", {
      is: "10000", // when industry_id is exactly 100
      then: () => yup.string().required(t("INDUSTRY_MANDATORY")), // industry is required
      otherwise: () => yup.string().notRequired() // not required otherwise
    })
  })
}

export const PersonalInformationProfileSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    name: yup.string().required(t("NAME_MANDATORY")),
    surname: yup.string().required(t("SURNAME_MANDATORY")),
    profession: yup.string().required(t("PROFESSION_MANDATORY")),
    birth_date: yup.string().required(t("BIRTHDATE_MANDATORY")),
    gender: yup.string().required(t("GENDER_MANDATORY")),
    industry_id: yup.string().required(t("INDUSTRY_MANDATORY")),
    industry: yup.string().when("industry_id", {
      is: "10000", // when industry_id is exactly 100
      then: () => yup.string().required(t("INDUSTRY_MANDATORY")) // industry is required      otherwise: () => yup.string().notRequired() // not required otherwise
    }),
    country: yup.number().required(t("COUNTRY_MANDATORY")),
    city: yup.number().nullable().required(t("COUNTRY_MANDATORY"))
  })
}

// Personal Information for Profile
export interface IPersonalInformationProflePayload extends PersonalInformation {
  country: number | string
  city: number | null
}

export interface Certificate {
  title: string
  establishment: string
  issue_date?: string | null
}
export const CertificateValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    title: yup.string().required(t("CERTIFICATE_REQUIRED")),
    establishment: yup.string().required(t("ORGANIZATION_TITLE_REQUIRED")),
    issue_date: yup.string().nullable()
  })
}

export interface Education {
  establishment: string
  degree: string
  direction: string
  start_date?: string | null
  end_date?: string | null
}
export const EducationValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    establishment: yup.string().required(t("UNIVERSITY_MANDATORY")),
    degree: yup.string().required(t("DEGREE_MANDATORY")),
    direction: yup.string().required(t("FACULTY_MANDATORY")),
    start_date: yup
      .string()
      .nullable()
      .when("end_date", {
        is: (end_date: any) => !!end_date,
        then: () => yup.string().required(t("END_DATE_REQUIRED_IF_START")),
        otherwise: () => yup.string().nullable()
      }),
    end_date: yup.string().nullable("")
  })
}

export interface Training {
  title: string
  establishment: string
  start_date?: string | null
  end_date?: string | null
}

export const TrainingValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    title: yup.string().required(t("TRAINING_TITLE_REQUIRED")),
    establishment: yup.string().required(t("ORGANIZATION_REQUIRED")),
    start_date: yup
      .string()
      .nullable()
      .when("end_date", {
        is: (end_date: any) => !!end_date,
        then: () => yup.string().required(t("END_DATE_REQUIRED_IF_START")),
        otherwise: () => yup.string()
      }),
    end_date: yup.string().nullable()
  })
}
//export interface of Training validation schema

export enum LanguageLevelsEnum {
  Native = "native",
  Fluent = "fluent",
  Intermediate = "intermediate",
  Good = "good",
  Beginner = "beginner"
}

export const optionLanguageLevels = [
  { id: 1, label: "NATIVE", value: LanguageLevelsEnum.Native },
  { id: 2, label: "FLUENT", value: LanguageLevelsEnum.Fluent },
  { id: 3, label: "GOOD", value: LanguageLevelsEnum.Good },
  { id: 4, label: "INTERMEDIATE", value: LanguageLevelsEnum.Intermediate },
  { id: 5, label: "BEGINNER", value: LanguageLevelsEnum.Beginner }
]
export interface ILanguageNaturalPerson {
  level: LanguageLevelsEnum
  language: string
}
export const LanguageValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    level: yup.mixed<LanguageLevelsEnum>().oneOf(Object.values(LanguageLevelsEnum)).required(t("LEVEL_REQUIRED")),
    language: yup.string().required(t("LANGUAGE_REQUIRED"))
  })
}

export interface Address {
  country: number
  city: number | null
  postal_code?: string | null
  address?: string | null
}
export const AddressValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    country: yup.string().required(t("COUNTRY_MANDATORY"))
  })
}

export interface Contact {
  mobile: string
  email: string
  show_contact_info: boolean
}
export const ContactValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    mobile: yup.string().required(t("MOBILE_REQUIRED")),
    email: yup.string().email(t("WRONG_EMAIL_FORMAT")).required(t("EMAIL_MANDATORY")),
    show_contact_info: yup.boolean().required(t("REQUIRED"))
  })
}

export interface WorkExperience {
  position: string
  company: string
  employment_type?: string | null
  job_type?: string | null
  start_date: string
  end_date?: string | null
  industry_id: number | string
  industry?: string | null
  description?: string | null
  current_position?: boolean | null
}
export const WorkExperienceValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    position: yup.string().required(t("POSITION_REQUIRED")),
    company: yup.string().required(t("COMPANY_REQUIRED")),
    employment_type: yup.string().nullable(),
    job_type: yup.string().nullable(),
    industry_id: yup.string().required(t("INDUSTRY_MANDATORY")),
    industry: yup
      .string()
      .nullable()
      .when("industry_id", {
        is: 100, // when industry_id is exactly 100
        then: () => yup.string().required(t("INDUSTRY_MANDATORY")), // industry is required
        otherwise: () => yup.string().nullable() // not required otherwise
      }),

    start_date: yup.string().required(t("START_DATE_REQUIRED")),
    end_date: yup.string().when("current_position", {
      is: true, // when current_position is true
      then: () => yup.string().nullable(), // make end_date not required
      otherwise: () => yup.string().required(t("END_DATE_REQUIRED")) // otherwise, make it required
    }),

    current_position: yup.boolean().nullable(),
    description: yup.string().nullable()
  })
}

// export const SkillsSoftAndTechnicalValidationSchema = yup.object().shape({
//   skill: yup
//     .string()
//     .test('len', 'მინიმუმ 2 ასო', val => val && val.length >= 2)
//     .required('აუცილებელია'),
//   soft_skill: yup.string().test('len', 'მინიმუმ 2 ასო', val => val && val.length >= 2),
//   skills: yup.array(),
//   soft_skills: yup.array()
// })

export const SkillsValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    skill: yup
      .string()
      // .test('len', 'მინიმუმ 2 ასო', val => (typeof val === 'string' ? val.length >= 2 : false))
      .required(t("REQUIRED"))
  })
}

export const SoftSkillsValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    soft_skill: yup
      .string()
      // .test('len', 'მინიმუმ 2 ასო', val => (typeof val === 'string' ? val.length >= 2 : false))
      .required(t("REQUIRED"))
  })
}

export interface SocialLink {
  link: string
  type?: string | null
}
export interface AdditionalInformation {
  citizenship?: string
  driving_licenses?: string[]
  current_salary?: number
  hobbies?: string
  about_me?: string
  disabled_person: boolean // This field is required in your schema
  links?: SocialLink[]
  link?: string
}
export const AdditionalInformationValidationSchema = yup.object().shape({
  citizenship: yup.string().optional(),
  driving_licenses: yup.array().of(yup.string()).optional(),
  current_salary: yup
    .number()
    .nullable()
    .transform((_, originalValue) => (originalValue === "" ? null : Number(originalValue)))
    .optional(),
  hobbies: yup.string().optional(),
  about_me: yup.string().optional(),
  disabled_person: yup.boolean().required(),
  links: yup
    .array()
    .of(
      yup.object().shape({
        link: yup.string().url().required()
      })
    )
    .nullable()
})
// export const linkValidationSchema = yup.object({
//   link: yup
//     .string()
//     .matches(/^(https:\/\/|www\.|google\.com$)/, 'ჩაწერეთ ვალიდური ბმული (მაგ: https://www.example.com)')
//     .required('ბმული აუცილებელია') // Required field
// })

export const LinkValidationSchema: any = () => {
  const { t } = useTranslation("common")

  return yup.object({
    link: yup
      .string()
      // The regex is applied only if there is some non-empty input
      .matches(/^(https:\/\/|www\.|google\.com$)/, {
        message: t("WRITE_VALID_LINK"),
        excludeEmptyString: true // Ensures the regex is not applied to an empty string
      })
  })
}

export const ProfileValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    user_name: yup.string().required(t("REQUIRED")),
    user_surname: yup.string().required(t("REQUIRED")),
    birth_year: yup.number().required(t("REQUIRED")),
    phone: yup.string().required(t("REQUIRED")),
    email: yup.string().required(t("REQUIRED")),
    gender_id: yup.number().required(t("REQUIRED")),
    otp: yup.number()
  })
}

export interface Profile {
  user_name: string
  user_surname: string
  birth_year: number
  phone: string
  email: string
  gender_id: number
  otp?: number
}

export const PasswordValidationSchema = () => {
  const { t } = useTranslation("common")

  return yup.object().shape({
    current_password: yup.string().required(t("REQUIRED")),
    password: yup.string().required(t("REQUIRED")),
    password_check: yup
      .string()
      .required(t("REQUIRED"))
      .oneOf([yup.ref("password"), ""], t("PASSWORDS_DONT_MATCH"))
  })
}

export interface Password {
  current_password: string
  password: string
  password_check: string
}

// ** Tailwind Styled
import tw from "tailwind-styled-components"

interface Props {
  $error?: boolean
}

export const TextareaBox = tw.div`
 w-full relative 
`
// max-w-[397px]
export const TextareaStyled = tw.textarea<Props>`
pt-6 pb-2 focus:border-secondary-100 focus:outline-none font-tbcx-regular text-2sm border border-neutral-40 rounded-lg px-4 h-full w-full text-secondary-100

${p => p.$error && "focus:border-error-100 border-error-100"}
`

interface LabelProps {
  $focused: boolean
  $error?: boolean
}
export const LabelStyled = tw.label<LabelProps>`
    pointer-events-none	 font-tbcx-regular text-secondary-70 absolute left-4 transition-all
    ${p => (p.$focused ? "top-2.5 text-xs" : "top-6 transform -translate-y-1/2 text-2sm")}
    ${p => p.$error && "text-error-100"}

`

import { useMediaQuery } from "react-responsive"

export const useScreenSize = () => {
  const isMobile = useMediaQuery({ maxWidth: "640px" })
  const isMd = useMediaQuery({ maxWidth: "768px" })
  const isTablet = useMediaQuery({ maxWidth: "1024px" })
  const isLaptopMax = useMediaQuery({ maxWidth: "1280px" })
  const isLaptopMin = useMediaQuery({ minWidth: "1024px" })
  const isDesktop = useMediaQuery({ minWidth: "1280px" })

  return { isMobile, isTablet, isLaptopMax, isLaptopMin, isDesktop, isMd }
}

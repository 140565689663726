import { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react"
import Typography from "../../../typography"
import { LabelStyled, TextareaBox, TextareaStyled } from "./Textarea.styles"

interface Props {
  label?: string
  error?: string
  className?: string
  required?: boolean
  type?: "text" | "number" | "textarea" | "email" | "date"
  value: string
  name: string
  onChange: any
  readOnly?: boolean
  minHeight?: string
  customOnChange?: any
}
const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      onChange,
      type = "text",
      value,
      name,
      error,
      className,
      required,
      readOnly,
      minHeight,
      customOnChange,
      ...restProps
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (value !== "") {
        setFocused(true)
      } else {
        setFocused(false)
      }

      if (onChange) {
        const newValue = e.target.value
        onChange(newValue)
      }
      if (customOnChange) {
        customOnChange(e.target.value)
      }
    }

    return (
      <TextareaBox className={className}>
        <>
          <TextareaStyled
            ref={ref}
            style={{ minHeight: minHeight }}
            name={name}
            value={value ?? ""}
            onChange={handleInputChange}
            $error={!!error}
            {...restProps}
            onFocus={() => setFocused(true)}
            onBlur={() => !value && setFocused(false)}
            readOnly={readOnly}
          />
          {/* If we don't have value display Label  */}
          {/* OR  */}
          {/* If we have value and it is over 400 length hide it  */}
          {(!value || (value && value.length < 1)) && (
            <LabelStyled $focused={!!(focused || value)} htmlFor={name} $error={!!error}>
              {label}
              {required && <span className="text-error-100">*</span>}
            </LabelStyled>
          )}
        </>

        {error && (
          <Typography type="small" className="pl-4 pt-1 text-error-100">
            {error}
          </Typography>
        )}
      </TextareaBox>
    )
  }
)

export default Textarea

import React, { useRef, useState } from "react"
import { Control, Controller } from "react-hook-form"
import Typography from "../../../typography"
import { InputBase, InputContainer, InputWrapper, LabelStyled } from "../Input/Input.styles"
import Textarea from "../Textarea"
import Link from "next/link"
import Button from "src/views/components/common/Button/Button"
import { cx } from "class-variance-authority"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  className?: string
  required?: boolean
  type?: "text" | "number" | "textarea" | "email" | "date" | "tel" | "password"
  textarea?: boolean
  minHeight?: string
  autoComplete?: string
  button?: React.ReactElement
  control: Control<any>
  onKeyDown?: any
  innerButtonText?: string
  innerButtonDisabled?: boolean
  innerButtonAction?: () => void
  onKeyUp?: any
  buttonStyle?: boolean
  maxLength?: number
  customOnChange?: any
}

const ControlledField = ({
  textarea = false,
  label,
  autoComplete,
  name,
  required,
  autoFocus,
  onKeyUp,
  maxLength,
  onKeyDown,
  defaultValue = "",
  className,
  minHeight,
  type = "text",
  button,
  control,
  disabled = false,
  innerButtonText = "",
  innerButtonDisabled = false,
  buttonStyle = false,
  customOnChange,
  innerButtonAction = () => {}
}: Props) => {
  const [isFocused, setInputFocused] = useState(false)
  const textareaRef = useRef<HTMLDivElement>(null)

  const handleInputFocus = () => setInputFocused(true)
  const handleInputBlur = () => setInputFocused(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: any) => {
    if (maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength)
    }
    field.onChange(e)
    if (customOnChange) {
      customOnChange(e.target.value)
    }
  }

  if (!textarea) {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <InputContainer className={className}>
            <InputWrapper>
              <InputBase
                autoFocus={autoFocus}
                $error={!!fieldState.error}
                onWheel={(e: any) => e.target?.blur()}
                type={type}
                autoComplete={autoComplete ?? "off"}
                onKeyDown={onKeyDown}
                onKeyPress={e => type === "number" && !/[0-9]/.test(e.key) && e.preventDefault()} // numbers only
                {...field}
                onChange={e => handleChange(e, field)}
                maxLength={maxLength}
                value={field.value ?? ""}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                disabled={disabled}
              />
              {innerButtonText.length > 0 && (
                <Button
                  intent="text"
                  onClick={() => innerButtonAction()}
                  className={
                    !buttonStyle
                      ? cx(
                          `absolute right-0 top-0 mr-3 flex cursor-pointer items-center justify-center gap-2 rounded-xl px-5 py-3 no-underline transition-all`,
                          innerButtonDisabled
                            ? "pointer-events-none bg-gray-200 text-gray-200 opacity-50"
                            : "bg-primary-100/10 text-primary-100 hover:bg-primary-100/20",
                          "mt-2"
                        )
                      : cx(
                          `absolute right-0 top-1 mr-1.5 flex cursor-pointer items-center justify-center rounded-[6px] px-5 no-underline transition-all hover:no-underline`,
                          innerButtonDisabled
                            ? "pointer-events-none bg-neutral-20 text-secondary-30 opacity-50"
                            : "bg-primary-100/10 text-primary-100 hover:bg-primary-100/20"
                        )
                  }
                  text={innerButtonText}
                  textClassName={` text-[14px] ${!innerButtonDisabled ? "text-primary-100" : ""}`}
                />
              )}
              <LabelStyled
                htmlFor={name}
                className="left-4"
                $error={!!fieldState.error}
                $focused={(field.value !== "" && field.value !== null) || isFocused}
              >
                {label}
                {required && <span className="text-error-100">*</span>}
              </LabelStyled>
              {button && <div className="absolute right-4 top-1/2 -translate-y-1/2 transform">{button}</div>}
            </InputWrapper>

            {fieldState.error && (
              <Typography type="small" className="pl-4 pt-1 text-error-100">
                {fieldState.error.message}
              </Typography>
            )}
          </InputContainer>
        )}
      />
    )
  } else {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        render={({ field, fieldState }) => (
          <Textarea
            minHeight={minHeight}
            className={className}
            error={fieldState.error && fieldState.error.message}
            required={required}
            label={label}
            {...field}
            customOnChange={customOnChange}
          />
        )}
      />
    )
  }
}

export default ControlledField

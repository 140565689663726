import { useQuery } from "@tanstack/react-query"
import LandingService from "src/server/services/LandingService"
import { IMainPageDataResponse } from "src/types/commonTypes"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import { IPublicCategoryResponse, IMainPageV2 } from "../../types/commonTypes"
import { QUERY_KEYS } from "./queryKeys"
import { useRouter } from "next/router"

const useIndex = () => {
  const router = useRouter()
  const mainPageQuery = useQuery<IMainPageDataResponse>({
    queryKey: [QUERY_KEYS.MAIN_PAGE],
    queryFn: fetchMainPage,
    enabled: false,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  const mainPageV2Query = useQuery<IMainPageV2>({
    queryKey: [QUERY_KEYS.MAIN_PAGE_V2],
    queryFn: getMainPageV2,
    enabled: true,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  const popularCategoriesQuery = useQuery<IPublicCategoryResponse[]>({
    queryKey: [QUERY_KEYS.PUBLIC_POPULAR_CATEGORIES],
    queryFn: fetchPopularCategories,
    enabled: true,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  const topCompaniesQuery = useQuery<IPublicCategoryResponse[]>({
    queryKey: [QUERY_KEYS.TOP_COMPANIES],
    queryFn: fetchTopCompanies,
    enabled: false
  })
  const benefitsQuery = useQuery<{ id: number; title: string; icon: string }[]>({
    queryKey: [QUERY_KEYS.GET_BENEFITS],
    queryFn: getBenefits,
    enabled: false,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  return {
    mainPageQuery: mainPageQuery,
    mainPageData: mainPageQuery.data,
    popularCategoriesQuery,
    topCompaniesQuery,
    benefitsQuery,
    mainPageV2Query,
    mainPageDataV2: mainPageV2Query.data
  }
}

export const fetchMainPage = async () => {
  try {
    const response: any = await LandingService.getMainPageData()

    return response.data
  } catch (e: any) {
    console.error("GET MAIN PAGE DATA ERROR")
    throw e // Rethrow the error
  }
}

export const getMainPageV2 = async () => {
  try {
    const response: any = await LandingService.getMainPageV2()

    return response.data
  } catch (e: any) {
    console.error("GET MAIN PAGE DATA ERROR")
    throw e // Rethrow the error
  }
}

export const fetchPopularCategories = async () => {
  try {
    const response: any = await LandingService.getPopularCategories()

    return response.data
  } catch (e: any) {
    console.error("GET POPULAR CATEGORIES ERROR")
    throw e // Rethrow the error
  }
}

export const getBenefits = async () => {
  try {
    const response: any = await LandingService.getBenefits()

    return response.data
  } catch (e: any) {
    console.error(e)
  }
}

export const fetchTopCompanies = async () => {
  try {
    const response: any = await LandingService.getTopCompanies()

    return response.data
  } catch (e: any) {
    console.error("GET POPULAR CATEGORIES ERROR")
    throw e // Rethrow the error
  }
}

export default useIndex
